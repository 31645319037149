/* eslint-disable */
import "./examModal.scss";
import React, { useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  staggerOne,
  modalOverlayVariants,
  modalVariants,
  modalFadeInUpVariants,
} from "../../motionUtils";
import { hideModalPlay } from "../../redux/playmodal/modal.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPlayModalContent,
  selectPlayModalState,
} from "../../redux/playmodal/modal.selectors";
import { BASE_IMG_URL, FALLBACK_IMG_URL, BASE_API_URL } from "../../requests";
import { VscChromeClose } from "react-icons/vsc";
import { dateToYearOnly } from "_helpers/utils";
import { FaMinus, FaPlay, FaPlus, FaHeart } from "react-icons/fa";
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
import {
  addToFavourites,
  removeFromFavourites,
} from "../../redux/favourites/favourites.actions";
import useOutsideClick from "../../hooks/useOutsideClick";
import ReactPlayer from "react-player/lazy";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Button,
  ButtonGroup,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import LessonBox from "components/CourseDetail/LessonBox";
import FinishedExam from "components/CourseDetail/FinishedExam";
import { useMyExams } from "hooks/useMyExams";

import { useExamViewModal } from "hooks/useExamViewModal";
import { useExams } from "hooks/useExams";
import { useTests } from "hooks/useTest";
import { useMyLessons } from "hooks/useMyLessons";

const ExamViewModal = () => {
  const exams = useExams();
  const tests = useTests();
  const myExam = useMyExams();

  const examModalActions = useExamViewModal();
  const myLessons = useMyLessons();

  const dispatch = useDispatch();
  const modalClosed = examModalActions.isViewModal; //useSelector(selectPlayModalState);
  const modalData = examModalActions.examViewModalData;
  const modalContent = useSelector(selectPlayModalContent);
  const [playPosition, setPlayPosition] = React.useState(0);
  const [playing, setPlaying] = React.useState(true);

  const [exam, setExam] = React.useState();

  const [examView, setExamView] = React.useState([]);

  const {
    item,
    courseId,
    overview,
    code,
    fallbackTitle,
    titleImageUrl,
    backdrop_path,
    release_date,
    first_air_date,
    genresConverted,
    isFavourite,
    poster,
  } = modalContent;
  const joinedGenres = genresConverted
    ? genresConverted.join(", ")
    : "Not available";
  //const maturityRating = adult === undefined ? "Not available" : adult ? "Suitable for adults only" : "Suitable for all ages";
  const reducedDate = release_date
    ? dateToYearOnly(release_date)
    : first_air_date
    ? dateToYearOnly(first_air_date)
    : "Not Available";
  const modalRef = useRef();

  const handleModalClose = () => {
    myLessons.onUpdate(modalData?.datas?.courseId, modalData?.datas?.lessonId, {
      videoPosition: playPosition,
    });

    if (modalData?.onRefreshCourse) {
      console.log(" refreshing......");
      modalData?.onRefreshCourse(modalData?.datas?.courseId);
    }

    if (modalData?.onCompleteCourse) {
      console.log(" onCompleteCourse......");
      modalData?.onCompleteCourse(modalData?.datas?.courseId);
    }

    examModalActions.setDisableExamViewModal();
    myExam.setIsFinished(false);
  };

  const handleAdd = (event) => {
    event.stopPropagation();
    dispatch(addToFavourites({ ...modalContent, isFavourite }));
  };
  const handleRemove = (event) => {
    event.stopPropagation();
    dispatch(removeFromFavourites({ ...modalContent, isFavourite }));
    if (!modalClosed) handleModalClose();
  };
  const handlePlayAnimation = (event) => {
    event.stopPropagation();
    handleModalClose();
  };
  useOutsideClick(modalRef, () => {
    //	if (!modalClosed) handleModalClose();
  });

  const handleProgress = (state) => {
    console.log("onProgress", state);

    // We only want to update time slider if we are not currently seeking
    if (!state.seeking) {
      //this.setState(state)
      setPlayPosition(state.playedSeconds);
    }
  };

  const handlePause = () => {
    console.log("onPause");
    setPlaying(false);
  };

  const handleSeek = (e) => {
    console.log("onSeek");
    console.log(e);
  };

  const handleDuration = (duration) => {
    console.log("onDuration", duration);
  };

  const dataExamView = [
    {
      id: 0,
      name: "Test Name 1",
      answer: "Answer 1",
    },
    {
      id: 1,
      name: "Test Name 2",
      answer: "Answer 2",
    },
    {
      id: 2,
      name: "Test Name 3",
      answer: "Answer 3",
    },
    {
      id: 3,
      name: "Test Name 4",
      answer: "Answer 4",
    },
    {
      id: 4,
      name: "Test Name 5",
      answer: "Answer 5",
    },
    {
      id: 5,
      name: "Test Name 6",
      answer: "Answer 6",
    },
    {
      id: 6,
      name: "Test Name 7",
      answer: "Answer 7",
    },
    {
      id: 7,
      name: "Test Name 8",
      answer: "Answer 8",
    },
    {
      id: 8,
      name: "Test Name 9",
      answer: "Answer 9",
    },
    {
      id: 9,
      name: "Test Name 10",
      answer: "Answer 10",
    },
  ];

  useEffect(async () => {
    if (modalData?.datas?.examId) {
      // await exams.onGetById(modalData?.datas?.examId);
      // await tests.onGetByExamId(modalData?.datas?.examId);
      setExamView(dataExamView);
    }
  }, [modalData]);

  return (
    <AnimatePresence exitBeforeEnter>
      {!modalClosed && (
        <>
          <motion.div
            variants={modalOverlayVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            key="modalOverlay"
            className={`Modal__overlay ${modalClosed && "Modal__invisible"}`}
          >
            <motion.div
              key="modal"
              variants={modalVariants}
              ref={modalRef}
              className={`Modal__wrp ${modalClosed && "Modal__invisible"}`}
            >
              <motion.button
                className="Modal__LessonClosebtn"
                onClick={handleModalClose}
              >
                <VscChromeClose />
              </motion.button>
              <Row
                style={{ paddingTop: 40, paddingLeft: 20, paddingRight: 20 }}
              >
                <Col sm="12">
                  {/* {exams?.datas && tests?.examTests && ( */}
                  {examView.map((examViewData) => (
                    <>
                      <Row className="pt-3">
                        <Col md={11} className="pt-1 mr-2 font-weight-bold">
                          {examViewData.id + 1}.) {examViewData.name}
                        </Col>
                      </Row>
                      <Row className="pt-3 ">
                        <Col md={11} className="ml-4">
                          <FormGroup tag="fieldset">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name={`radio${examViewData.id}` - 1}
                                />{" "}
                                {examViewData.answer + " - 1"}
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={11} className="ml-4">
                          <FormGroup tag="fieldset">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name={`radio${examViewData.id}` - 1}
                                />{" "}
                                {examViewData.answer + " - 2"}
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={11} className="ml-4">
                          <FormGroup tag="fieldset">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name={`radio${examViewData.id}` - 1}
                                />{" "}
                                {examViewData.answer + " - 3"}
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={11} className="ml-4">
                          <FormGroup tag="fieldset">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name={`radio${examViewData.id}` - 1}
                                />{" "}
                                {examViewData.answer + " - 4"}
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="pb-2 mb-40 border-bottom border-black-50"></div>
                    </>
                  ))}
                  {/* )} */}
                </Col>
              </Row>

              {/* <ReactPlayer url={`${modalData?.url}`} className="Modal__image--img"
								controls={true}
								width='100%'
								height='100%'
								playing={playing}
								onProgress={handleProgress}
								onDuration={handleDuration}
								onPause={handlePause}
								onSeek={handleSeek}
							/> */}
              <div className="Modal__image--wrp">
                <div className="Modal__image--shadow" />

                {/* <img
									className="Modal__image--img"

									src={poster ? poster?.posterUrl : (poster?.posterUrl ? poster?.posterUrl : FALLBACK_IMG_URL)}
									alt={fallbackTitle}
								/> */}
                <div className="Modal__image--buttonswrp">
                  {/* <Link
										className="Modal__image--button"
										onClick={handlePlayAnimation}
										// to={'/play'}
										to={`/course/${courseId}`}
									>
										<FaPlay />
										<span>ลงทะเบียน / เรียน</span>
									</Link>
									{!isFavourite
										? (
											<button className='Modal__image--button-circular' onClick={handleAdd}>
												<FaHeart />
											</button>
										) : (
											<button className='Modal__image--button-circular' onClick={handleRemove}>
												<FaHeart />
											</button>
										)} */}
                </div>
              </div>
              <motion.div
                variants={staggerOne}
                initial="initial"
                animate="animate"
                exit="exit"
                className="Modal__info--wrp"
              >
                <motion.h3
                  variants={modalFadeInUpVariants}
                  className="Modal__info--title"
                >
                  {" "}
                  {fallbackTitle}
                </motion.h3>
                <motion.p
                  variants={modalFadeInUpVariants}
                  className="Modal__info--description"
                >
                  {overview}
                </motion.p>
                {/* <motion.hr
                  variants={modalFadeInUpVariants}
                  className="Modal__info--line"
                /> */}
                {/* <motion.h4 variants={modalFadeInUpVariants} className="Modal__info--otherTitle"><b>{`หลักสูตรบังคับ`}</b></motion.h4> */}
                {/* <motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Categories: </span>
									<span className="Modal__info--row-description">{joinedGenres}</span>
								</motion.div> */}
                {/* <motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>
										{release_date ? "Release date: " : "First air date: "}
									</span>
									<span className="Modal__info--row-description">{reducedDate}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Reviseion: </span>
									<span className="Modal__info--row-description">{`1.0`}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Chapter: </span>
									<span className="Modal__info--row-description">{`10`}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Learners: </span>
									<span className="Modal__info--row-description">{`9999`}</span>
								</motion.div> */}
              </motion.div>
            </motion.div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default ExamViewModal;
