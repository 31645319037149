/* eslint-disable */
import {
  useSetRecoilState,
  useRecoilValue,
  useRecoilState,
  atom,
} from "recoil";
import { history, useFetchWrapper, apiBaseURL } from "_helpers";

const examAtom = atom({
  key: "examView",
  default: true,
});

const examDataAtom = atom({
  key: "examViewData",
  default: {},
});

export { useExamViewModal };

function useExamViewModal() {
  const baseUrl = `${apiBaseURL}`;
  const fetchWrapper = useFetchWrapper();
  const [isViewModal, setExamViewModalState] = useRecoilState(examAtom);
  const [examViewModalData, setExamViewModalDataState] =
    useRecoilState(examDataAtom);

  return {
    setExamViewModal,
    setDisableExamViewModal,
    isViewModal: isViewModal,
    examViewModalData: examViewModalData,
  };

  function setExamViewModal({ url, datas, onRefreshCourse, onCompleteCourse }) {
    setExamViewModalState(false);
    setExamViewModalDataState({
      url,
      datas,
      onRefreshCourse,
      onCompleteCourse,
    });
  }

  function setDisableExamViewModal() {
    setExamViewModalState(true);
    setExamViewModalDataState({});
  }
}
