/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  //   CardTitle,
  //   CardText,
  Row,
  Col,
  Table,
  CardImg,
} from "reactstrap";

import i18n from "i18next";
import { FaPlay, FaCheckDouble, FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

import { usePlayVideo } from "hooks/usePlayVideo";
import { useExamModal } from "hooks/useExamModal";
import { useAuthen } from "hooks/useAuthen";
import { useExamViewModal } from "hooks/useExamViewModal";

const CourseAndExam = ({
  course,
  isRegister,
  onRefreshCourse,
  onCompleteCourse,
}) => {
  const playerActions = usePlayVideo();
  const examModal = useExamModal();
  const userActions = useAuthen();
  const examViewModal = useExamViewModal();

  return (
    <Table striped>
      {/* <thead>
                <tr>
                    <th>#</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Username</th>
                </tr>
            </thead> */}
      <tbody>
        {course?.lessons?.map((lesson) => (
          <tr>
            <td width={200} style={{ paddingTop: 20 }}>
              {i18n.language == "en"
                ? lesson.lessonName
                : lesson.lessonNameLocale}
            </td>
            <td style={{ paddingTop: 20 }}>
              {i18n.language == "en"
                ? lesson.lessonTitle
                : lesson.lessonTitleLocale}
              {/* {` ( ${lesson.lessonId} ) `} {` ( ${lesson.seq} ) `} */}
            </td>
            <td width={150} style={{ paddingTop: 20 }}>
              {lesson?.isAllow &&
                userActions?.auth &&
                lesson?.lessonMedias &&
                isRegister && (
                  <div>
                    <Link
                      className={`Lesson-info${
                        // lesson?.isComplete && lesson?.type != 1
                        //   ? "--icon-disable"
                        //   :
                        "--icon"
                        } icon--play`}
                      onClick={() => {
                        if (userActions?.auth) {
                          if (isRegister) {
                            // is already register
                            let datas = {
                              url: lesson?.videoUrl,
                              sourceList: lesson?.lessonMedias,
                              onRefreshCourse: onRefreshCourse,
                              onCompleteCourse: onCompleteCourse,
                              //isAllow: lesson?.isComplete,
                              isAllow: lesson?.isAllow,
                              isComplete: lesson?.isComplete,
                              datas: {
                                courseId: course.id,
                                lessonId: lesson.lessonId,
                                examId: lesson.examId,
                              },
                            };

                            if (lesson.type == 1) {
                              playerActions.setPlay(datas);
                            } else {
                              if (lesson?.isComplete) {
                                // examViewModal.setExamViewModal(datas);
                              } else {
                                examModal.setExamModal(datas);
                              }
                            }
                          }
                        } else {
                          history.push("/login");
                        }
                      }}
                      to={"#"}
                    //to={`/course`}
                    >
                      {lesson.type == 1 ? <FaPlay /> : <FaEdit />}
                    </Link>
                    {lesson?.isComplete && (
                      <div className="Lesson-info--icon icon--play">
                        <FaCheckDouble />
                      </div>
                    )}
                  </div>
                )}
            </td>
            {/* <td>
                            {i18n.language == "en"
                                ? lesson.lessonTitle
                                : lesson.lessonTitleLocale}
                        </td> */}
            {/* <td>@mdo</td> */}
          </tr>
        ))}

        {/* <tr>
                    <td>1</td>
                    <td>
                        <div className="Lesson-info--icon icon--play">
                            <FaCheckDouble />
                        </div>
                        <div className="Lesson-info--icon icon--play">
                            <FaPlay />
                        </div>
                    </td>
                    <td>Otto</td>
                    <td>@mdo</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td colSpan={2}>Larry the Bird</td>
                    <td>@twitter</td>
                </tr> */}
      </tbody>
    </Table>
  );
};

export default CourseAndExam;
